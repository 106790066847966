<template>
  <div class="flex flex-col justify-between flex-wrap w-full px-12 main_content mt-12">
    <div class="w-2/3 relative" style="height: calc(100vh - 6rem)">
      <div class="flex flex-wrap">
        <div class="w-1/2 px-4 pt-6 pb-4 border-r border-grey-light-200">
          <p class="font-sansDemi text-base text-white mt-2">
            Discovery Results
          </p>
          <p class="font-sans text-base base-font-gray-lighter-180 mt-2" v-if="!scrolled">
            We have analyzed the users commenting
            on your selected videos and have found
            that they are subscribed to the following
            channels.
          </p>
        </div>
        <div class="w-1/2 px-6 pt-6 pb-4">
         
          <p class="font-sans text-base base-font-gray-lighter-180 mt-2" v-if="!scrolled">
            Discovery ID {{discoveryId}}
          </p>
          <div class="flex flex-wrap items-center justify-between mt-8" :class="{ 'topPos': scrolled }">
            <div class="flex flex-wrap items-center">
              
            </div>
            <!-- <button 
              @click="downloadResults"
              class="flex justify-between items-center h-7 ml-4 font-monoDemi inline-block text-xs px-3 h-7 rounded-2xl whitespace-nowrap text-black bg-white"
            >
              <span>Download Results</span>
              <Loader 
                v-if="loading"
                :animation="true"
                classes="w-4 h-4 ml-3"
              />
            </button> -->
          </div>
        </div>

        <div class="thumb-footer absolute bottom-0 w-full h-20 overflow-hidden z-40 flex" v-if="hover">
          <li v-for="videoId in thumbs.vidIds" :key="videoId">
            <span class="thumb" :style="`background-image: url('https://img.youtube.com/vi/${videoId}/mqdefault.jpg')`"></span>
          </li>
        </div>
      </div>

    <modal 
      name="rate-modal" 
      :clickToClose="true"
      :classes="[ 'z-50', 'bg-white', 'discoveryPlayerWrap' ]"
      :width="'50%'"
      :height="'50%'"
    >
      <closeIcon
        class="cursor-pointer bg-white rounded-full absolute top-3 right-3"
        @close="hideRateModal"
      />
      <div  class="flex justify-center items-center bg-black h-full">
        <div class="h-full w-2/5 flex items-center justify-center">
          <ratingComponent 
            :item="ratingData"
            @raiting="sendRating"
          />
        </div>
      </div>
    </modal>

    <modal 
      name="classifications-modal" 
      :clickToClose="true"
      :classes="[ 'z-50', 'bg-white', 'discoveryPlayerWrap' ]"
      :width="'50%'"
      :height="'50%'"
    >
      <closeIcon
        class="cursor-pointer bg-white rounded-full absolute top-3 right-3"
        @close="hideClassificationsModal"
      />
      <div class="flex justify-center items-center bg-black h-full">
        <div class="h-full w-4/5 flex items-center justify-center">
          <div class="w-2/3">
            <labelComponent
              :preSelected="preSelectedClassification"
              :item="ratingData"
              @label="sendLabel"
            />
          </div>
        </div>
      </div>
    </modal>

      <div class="border-t border-grey-light-200 h-3/4 overflow-y-auto" ref="results" @scroll="onScroll" :class="{ 'scrolled': scrolled }">
        <sorted-table 
          :values="data"
          @sort-table="onSortTable"
          ref="sortedTable"
          ascIcon="<span></span>"
          descIcon="<span></span>"
          class="w-full"
        >
          <thead class="border-b border-grey-light-200">
            <tr>
              <th
                v-for="(item, index) in columns"
                :key="item.key"
                scope="col"
                class="text-left"
                :class="[ 
                  index == 0 ? 'pl-4 w-1/3' : '',
                  index == 1 ? 'w-1/12' : '',
                  index == 2 ? 'w-1/12 border-r border-grey-light-200 px-3' : '',
                  index == 3 ? 'w-1/3 pl-3' : ''
                ]"
              >
                <sort-link 
                  v-if="item.key == 'title' || item.key == 'avgRatingCount' || item.key == 'countRatio'"
                  :name="item.key"
                  class="font-mono flex text-xs pt-4 pb-3 block font-normal select-none hover:text-white cursor-pointer"
                  :class="[ item.key == sortBy ? 'text-white' : 'base-font-gray-lighter-180' ]"
                >
                  {{ item.name }}
                </sort-link>
                <span
                  v-else
                  class="font-mono flex text-xs base-font-gray-lighter-180 pt-4 pb-3 block font-normal select-none"
                >
                  {{ item.name }}
                </span>
              </th>
            </tr>
          </thead>
          <template #body="sort">
            <tbody>
              <tr
                v-for="(item, index) in sort.values"
                :key="item.channel_id"
                :id="item.channel_id"
                :class="{ activeChannel: activeChannel === item.channel_id }"
              >
                <td class="cell-dashed initial">
                  <a :href="'https://www.youtube.com/channel/' + item.channel_id" target="_blank">
                    <div class="flex items-end py-2 ml-4">
                      <div class="relative inline-block">
                        <img 
                          :src="item.thumbnail"
                          class="w-6 h-6 rounded-full"
                          @mouseenter="hover = true, triggerThumbs(item.channel_id)"
                          @mouseleave="hover = false"
                        >
                        <span 
                          class="flex justify-center items-center text-white font-mono text-xs absolute w-4 h-4 base-bg-darker-110 top-1 -right-3.5"
                        >
                          {{ index + 1 }}
                        </span>
                      </div>
                      <p class="base-font-gray-lighter-400 text-sm font-sans ml-8">{{ item.title | truncate(24) }}</p>
                    </div>
                  </a>
                </td>
                <td class="border-r border-grey-light-200 cell-dashed">
                  <div class="flex py-2">
                    <p 
                      class="flex items-center base-font-gray-lighter-400 text-xs font-mono px-3 rounded"
                      style="background-color: #2a2a2a;"
                      v-tooltip="`${item.countRatio}% of all ${item.title}'s subscriptions, are in your seedlist. This channel has X subscriptions.`"
                    >
                      0 %
                    </p>
                  </div>
                </td>
                <td class="border-r border-grey-light-200 cell-dashed">
                  <div 
                    v-if="item.avgRatingCount >= 1" 
                    class="py-2 px-3 flex"  
                    @click="showRateModal('rate', item.channel_id, item.title, item.thumb)"
                  >
                    <span
                      class="inline-flex items-center justify-between px-2 rounded whitespace-nowrap cursor-pointer select-none"
                      style="min-width: 70px;"
                      :class="{
                        'bg-green font-green': item.avgRating <= 2,
                        'bg-orange font-orange': item.avgRating > 2 && item.avgRating < 3,
                        'bg-red font-red': item.avgRating >= 3
                      }"
                      v-tooltip="`${item.avgRatingCount} user(s) considers this channel ${calcAvgRating(item.avgRating)}% harmful. Click this button to give your own score.`"
                    >
                      <span class="py-0.5 font-sans text-xs pr-2">
                        <number
                          :ref="`number${item.channel_id}`"
                          :from="calcAvgRating(item.avgRating) - 10"
                          :to="calcAvgRating(item.avgRating)"
                          :duration="1"
                          :delay="2"
                          easing="Power1.easeOut"
                          class="font-sans text-xs"
                        />%
                      </span>
                      <span class="py-0.5 font-sans text-xs pl-2 border-l border-dashed border-gray-100">
                        {{ item.avgRatingCount }}
                      </span>
                    </span>
                  </div>

                  <div 
                    v-if="!('avgRating' in item) || item.avgRatingCount == 0" 
                    class="py-2 px-3"  
                    @click="showRateModal('rate', item.channel_id, item.title, item.thumbnail)"
                  >
                    <span class="flex items-center justify-center px-2 rounded border border-grey-light-200 hover:border-white base-font-gray-400 hover:text-white cursor-pointer">
                      <span class="flex py-0.5 font-sans text-xs select-none">
                        Rate
                      </span>
                    </span>
                  </div>

                </td>
                <td class="border-r border-grey-light-200 cell-dashed" style="max-width: 200px;">
                  <div class="flex justify-between items-start">
                    <div class="relative w-11/12 pb-3">
                      <ul class="flex flex overflow-x-auto no-scrollbar">
                        <template v-if="item.labels && item.labels.length">
                          <li
                            v-for="(item, index) in item.labels"
                            :key="`${item[0]}${index}`"
                            class="flex label transition ease-in-out delay-150 items-center ml-3 mt-3 base-bg-darker-110 rounded cursor-pointer select-none"
                            @click="showRateModal('classifications', item.channel_id, item.title, item.thumbnail, item[0])" 
                          >
                            <span class="font-sans text-xs text-white px-3 py-0.5">
                              {{ item[0] }}
                            </span>
                            <span class="font-sans text-xs text-white px-2 py-0.5 border-l border-dashed border-gray-200">
                              {{ item[1] }}
                            </span>
                          </li>
                        </template>
                      </ul>
                      <div 
                        class="absolute top-0 right-0 w-8 h-full"
                        :style="{
                          background: 'linear-gradient(270deg, #242424 -13.89%, rgba(36, 36, 36, 0) 71.33%)'
                        }"
                      ></div>
                    </div>
                    <div
                      @click="showRateModal('classifications', item.channel_id, item.title, item.thumbnail)" 
                      class="w-1/12 flex items-center justify-center my-3 text-center cursor-pointer mr-2">
                      <span class=" base-bg-darker-110 font-gray-100 leading-none w-5 h-5 rounded-full transition ease-in-out delay-150 add-button select-none">
                        +
                      </span>
                    </div>
                  </div>
                </td>
                <td class="cell-dashed">
                  <div class="flex justify-center items-center py-2">
                    <div @click="setRated(item.channel_id)">
                      <starFilledIcon v-if="item.rated" class="w-5 h-5 mx-3 text-white cursor-pointer"/>
                      <starIcon v-else class="w-5 h-5 mx-3 font-gray-100 cursor-pointer"/>
                    </div>
                    <graphIcon class="font-gray-100 cursor-pointer" @click.native="findNode(item.channel_id)"/>
                    <div class="mx-3">
                      <button 
                        @click="addToBucket(item.channel_id, item.thumbnail)"
                        class="base-bg-darker-120 rounded base-font-gray-lighter-400 text-xs text-white w-28 py-1 whitespace-nowrap select-none"
                        :style="{ 
                          backgroundColor: (channelAdding.alreadyInBucketPreDiscovery.includes(item.channel_id) || channelAdding.channelsAdded.includes(item.channel_id)) ? '#4633F5' : ''
                        }"
                      >
                        <Loader
                          :animation="true"
                          classes="w-2 h-2"
                          color="#fff"
                          v-if="channelAdding.requestLoading && channelAdding.currentlyLoading === item.channel_id"
                        />
                        <template v-if="channelAdding.alreadyInBucketPreDiscovery.includes(item.channel_id)">
                          In Bucket
                        </template>
                        <template v-else-if="channelAdding.channelsAdded.includes(item.channel_id)">
                          Added to Bucket
                        </template>
                        <template v-else>
                          Add to Bucket
                        </template>
                      </button>
                    </div>
                  </div>
                </td>
              </tr>
              <tr>
                <td></td>
                <td></td>
                <td></td>
                <td></td>
              </tr>
            </tbody>
          </template>
        </sorted-table>
      </div>
      <!-- <div class="flex flex-wrap w-full rounded-lg absolute bottom-6 black_block">
        <div class="w-1/2 pt-4 pl-6 pb-5 pr-4 border-r border-grey-darker-600">
          <p class="mb-3">

          </p>
        </div>
        <div class="w-1/2 pt-6 pl-6 pb-5">
          <p class="mb-3">
            <span class="text-white mr-2">Channels in Bucket</span>
            <span class="font-grey-darker-600">{{channelAdding.channelsInBucket.length}}/30</span>
          </p>
          <ul class="flex">
            <li 
              v-for="mostSubs in data.mostSubs.slice(0, 5)"
              :key="mostSubs.id"
              class="-mr-2"
            >
              <img 
                :src="`https://yt3.ggpht.com/${mostSubs.thumb}=s176-c-k-c0x00ffffff-no-rj-mo`"
                class="w-6 h-6 rounded-full"
              >
            </li>
          </ul>
        </div>
      </div> -->
    </div>

    <div class="w-1/3 graph">
      <div class="flex flex-col border-grey-light-800 base-bg-darker-210 border rounded-2xl h-full">
        <p class="w-full text-end font-mono text-base text-black pt-3 pr-4">
          Network Graph | Clusters
        </p>
        <div ref="graphcontainer" class="flex flex-wrap items-center flex-grow">
          <div 
            v-if="mostsubsLoading || nicheLoading"
            class="h-full graph inline-flex text-white"
          >
            Processing Graph Data.<br/>This can take a couple minutes
          </div>
          <div 
            v-else 
            class="h-full graph inline-flex" 
            ref="graph"
          >
            Loading
          </div>
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
  import ForceGraph from 'force-graph';
  import { warningToastConfig } from '@/helpers/constants'
  // import ToastMessages from '@/data/toast_messages.json'
  import { mapGetters } from 'vuex'
  import Loader from '@/components/Loader'
  import starIcon from '@/components/icons/star'
  import starFilledIcon from '@/components/icons/star-filled'
  import graphIcon from '@/components/icons/graph'
  import closeIcon from '@/components/icons/close-icon'
  import ratingComponent from '@/components/DiscoveryProcess/crowdsourcing/rating'
  import labelComponent from '@/components/DiscoveryProcess/crowdsourcing/label'
  import { SortedTable, SortLink } from "vue-sorted-table";
  import parseString from 'xml2js';

  export default {
    name: 'AnalisysStep2',
    props: {
      data: {
        type: Object,
        default: () => {},
      },
      discoveryId: String
    },
    components: {
      Loader,
      starIcon,
      graphIcon,
      starFilledIcon,
      closeIcon,
      ratingComponent,
      labelComponent,
      SortedTable,
      SortLink
    },
    data(){
      return {
        analysis: this.data.meta,
        scrolled: false,
        ratingData:{
          originalChannelId: "",
          originalChannelName: "",
          originalThumbUrl: ""
        },
        activeChannel: "",
        loading: false,
        mode: "mostSubs", // bestRatio
        mostSubs: this.data.mostSubs,
        bestRatio: this.data.bestRatio,
        initialSeed: [],
        videoscrapeIds: [],
        thumbs: {
          vidIds: []
        },
        hover: false,
        mostsubsLoading: false, // refactor?
        nicheLoading: false,
        graph: {},
        graphData: {},
        apiMetadata: {},
        channelAdding: {
          requestLoading: false, // Channel is being added to bucket
          currentlyLoading: "", // ID of channel that is being added to bucket
          channelsAdded: [], // Channels added by user during discovery
          alreadyInBucketPreDiscovery: [], // Channels already in bucket before discovery
          channelsInBucket: [] // All channels in bucket [ID's and Thumb]
        },
        columns: [
          { name: 'Channel Name', key: 'title' },
          { name: 'Sub%', key: 'countRatio' },
          { name: 'Harmful', key: 'avgRatingCount' },
          { name: 'Community Classifications', key: 'user_tags' }
        ],
        sortDir: 'avgRatingCount',
        sortBy: 'desc',
        preSelectedClassification: null
      }
    },
    computed: {
      ...mapGetters({
        channelData: 'discovery/channelData',
        jobData: 'discovery/jobData',
        selectedBucket: 'general/selectedBucket',
      })
    },
    methods: {
      onSortTable(sortBy, sortDir) {
        console.log('on sort table trigger')
        this.sortBy = sortBy
        this.sortDir = sortDir
      },
      setRated(id){
        this.$emit('setRated', {
          id: id,
          mode: this.mode
        })
      },
      showRateModal(type, channelId, channelTitle, channelThumb, label) {
        if(label) this.preSelectedClassification = label
        else this.preSelectedClassification = null
        this.ratingData = {
          originalChannelId: channelId,
          originalChannelName: channelTitle,
          originalThumbUrl: channelThumb
        }
        this.$modal.show(`${type}-modal`);
      },
      hideRateModal() {
        this.$modal.hide('rate-modal');
      },
      async sendRating(item){
        const { data } = await this.$axios.post('https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/add-crowdsource-data', item)
        this.hideRateModal()
        if (data.response === false) { 
          this.$toasted.show(data.message, warningToastConfig) 
        } else {
          this.$toasted.show(`Thank you for rating ${this.ratingData.originalChannelName}`, warningToastConfig)
        }
        this.$refs[`number${item.channelId}`][0].restart()
      },
      async sendLabel(item){
        const { data } = await this.$axios.post('https://fuafqdfged.execute-api.us-west-1.amazonaws.com/api/v1/add-crowdsource-data', item)
        this.hideClassificationsModal()

        if (data.response === false) { 
          this.$toasted.show(data.message, warningToastConfig) 
        } else {
          this.$toasted.show(`Thank you for classifying ${this.ratingData.originalChannelName}`, warningToastConfig)
        }

      },
      downloadResults(){

      },
      async getRss(id) {
        const response = await fetch(`https://proxy-rss.onrender.com/https://www.youtube.com/feeds/videos.xml?channel_id=${id}`);
        const data = await response.text();
        const xml = data;
        this.thumbs.vidIds = [];
        let _this = this;

        parseString.parseString(xml, function (err, result) {
          result.feed.entry.forEach((res) => {
            _this.thumbs.vidIds.push(res['yt:videoId'][0])
          })
        })
      },
      triggerThumbs(id){
        // if (id === false) { this.thumbs.vidIds = [] }
        this.getRss(id);
      },
      hideClassificationsModal(){
        this.$modal.hide('classifications-modal');
      },
      calcAvgRating(value){
        return Math.round((value * 100) / 5)
      },
      start(){
        this.showGraphDropDowns = true
      },
      alreadyActive() {
        this.$toasted.show(`Please wait while your current request is finished, before adding another channel.`, warningToastConfig)
      },
      async addToBucket(channelId, thumbnail) {
        if (this.selectedBucket.bucketId === null ) {
          this.$toasted.show('Select a bucket', warningToastConfig);
          this.$root.$refs.featureNav.showBucketPickerInactive();
          return false;
        }

        if(!this.channelAdding.channelsAdded.includes(channelId) && !this.channelAdding.alreadyInBucketPreDiscovery.includes(channelId)){
          if (this.channelAdding.requestLoading) return this.alreadyActive();
          this.channelAdding.requestLoading = true;
          this.channelAdding.currentlyLoading = channelId;
          

          const { data } = await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/add_channel`, {
            channelId,
            bucketId: this.selectedBucket.bucketId,
            fullScrape: true
          })

          console.log(data);

          this.channelAdding.requestLoading = false;
          this.channelAdding.channelsAdded.push(channelId);

          this.channelAdding.channelsInBucket.push({
            channelId: channelId,
            thumbnail: thumbnail
          })
        }

      },
      onScroll({ target: { scrollTop }}) {
        if (scrollTop > 10) {
          this.scrolled = true;
        } else if (scrollTop < 10) {
          this.scrolled = false
        }
      },
      findNode(id) {
        for (let i = 0; i < this.graphData.nodes.length; i++) {
          if (id === this.graphData.nodes[i].id) {
            this.graph.centerAt(this.graphData.nodes[i].x, this.graphData.nodes[i].y, 1000);
            this.graph.zoom(8, 2000);
          }
        }
      },
      goToItem(item) {
        const mostSubs = this.data.mostSubs;
        this.activeChannel = item.id;
        let allIds = [];

        for (let i = 0; i < mostSubs.length; i++) {
          allIds.push(mostSubs[i].channel_id);
        }

        if (allIds.includes(item.id)) {
          const elem = document.getElementById(item.id)
          elem.scrollIntoView({behavior: "smooth", block: "center", inline: "nearest"})
        }
      },
      async initGraph(graphData) {
        const returnNodeSize = (item) => {
          if (item.title === 'user') return .1
          return 2
        }

        const label = (item) => {
          if (item.title === "user") return `<div class="labelTwo"><span class="labeltext">Subscribed User</span></div>`
          return `<div class="labelTwo">
            <div><span class="labeltext">${item.title}</span><br/>
            <span class="labelsubs">Subscribers ${item.subs}</span></div>
            </div>`
        }

        let h = this.$refs.graphcontainer.clientHeight + (3*15); 
        let w = this.$refs.graphcontainer.clientWidth; 

        this.graphData = graphData;
        this.graphData.nodes = this.graphData.nodes.map(v => ({...v, selected: false}));

        this.graph = ForceGraph();
          this.graph(this.$refs.graph)
            .graphData(this.graphData)
            .nodeCanvasObject((node, ctx) => {
              const NODE_R = 3.5;


              let hoverNode = false;

              ctx.beginPath();
              if (node.title !== "user") {
                ctx.arc(node.x, node.y, NODE_R * 1.4, 0, 2 * Math.PI, false);
                ctx.fillStyle = node === hoverNode ? 'red' : 'transparent';
                ctx.fill();
              }

              const size = 10
              var img=new Image();
              img.src = `https://yt3.googleusercontent.com/${node.thumb}=s176-c-k-c0x00ffffff-no-rj-mo`;
              ctx.save();
              ctx.beginPath();              
              if (node.title === "user") return ctx.arc(node.x, node.y, .5, 0, 2 * Math.PI);
              ctx.arc(node.x, node.y, 4, 0, 2 * Math.PI);
              ctx.clip()
              ctx.closePath();
              ctx.drawImage(img, node.x - size / 2, node.y - size / 2, size, size);
            })
            .nodePointerAreaPaint((node, color, ctx) => {
              const size = 10;
              ctx.fillStyle = color;
              ctx.fillRect(node.x - size / 2, node.y - size / 2, size, size); // draw square as pointer trap
            })
            .height(h)
            .width(w)
            .nodeId('id')
            .nodeLabel((item) => label(item))
            .nodeVal((item) => returnNodeSize(item))
            .onNodeClick((item) => { 
              this.goToItem(item);
            })
            .linkVisibility(true)
            .linkColor(() => { return "gray" })
            .linkWidth(.1)
            .linkSource('source')
            .linkTarget('target')
            .cooldownTicks(200)
      },
    },
    async mounted() {
      this.$nextTick(() => {
        this.$refs.sortedTable.sortBy('asc', 'avgRatingCount');
      })

      fetch(`https://raditube-discovery-bucket.s3.us-west-1.amazonaws.com/${this.discoveryId}_graph.json`)
      .then(async res => {
        const graphData = await res.json()

        this.initGraph(graphData)
      })
      .catch(err => { console.log(err, 'err') })

      const { data } = await this.$axios.post(`${process.env.VUE_APP_BASE_URL}/api/v1/get-discovery-jobs`, {
        discoveryId: this.discoveryId
      })

      this.apiMetadata = data;
      // const channels = await this.$axios.get(`${process.env.VUE_APP_BASE_URL}/api/v1/get_channels_bucket/${data.bucket}`);

      // if (channels.data === false) {
      //   console.log('[ERROR] No channels in bucket')
      // } else {
      //   channels.data.forEach((res) => {
      //     this.channelAdding.alreadyInBucketPreDiscovery.push(res.channelId)
      //     this.channelAdding.channelsInBucket.push({
      //       channelId: res.channelId,
      //       thumbnail: res.thumbnail
      //     })
      //   })
      // }

    }
  }
</script>

<style lang="scss">
.cell-dashed{
  border-bottom: 1px dashed #313131;
}

.initial:hover {
  background: #313131
}

.main_content{
  height: calc(100vh - 6rem);
}
.grow{
  flex-grow: 1;
}
.black_block{
  background: rgba(21, 21, 21, 0.82);
  backdrop-filter: blur(4px);
}

.graph {
  height: calc(100vh -  3rem);
  margin-top: -3rem;
}


.channel:last-child {
  margin-bottom: 8rem;
}

.label:hover, .add-button:hover {
  background-color: #484848;
}

.scrolled {
  height: calc(100vh - 11rem)
}

.topPos {
  margin-top: 0 !important;
}

.gray {
  background-color: rgb(155, 155, 155);
}

.none {
  border: 1px solid #484848;
}

.scene-tooltip {
  background: black;
}

@keyframes bgColorAnim {
    0% {
        background-color: #444444;  
    }
    100% {
        background-color: #242424;
    }
}

.activeChannel {
  animation-name: bgColorAnim;
  animation-duration: 2s;
  animation-timing-function: linear;
  animation-delay: 500ms;
  animation-iteration-count: 1;
  animation-direction: normal;
  animation-fill-mode: none;
}

.hoverableLine:hover {
  background-color: #202020;
}

.nodeLabel {
  display: block;
  background: black;
  color: white;
}

li {
  list-style-type: none;
}

.thumb {
  display: block;
  width: 120px;
  height: 67.5px;
  background-size: cover;
  border-radius: 4px;
  margin-left: .5rem;
}

.tooltip {
  display: block !important;
  z-index: 10000;
  width: 200px;
}

.tooltip .tooltip-inner {
  background: black;
  color: white;
  border-radius: 1rem;
  font-size: .75rem;
  padding: 5px 10px 4px;
}

.tooltip .tooltip-arrow {
  width: 0;
  height: 0;
  border-style: solid;
  position: absolute;
  margin: 5px;
  border-color: black;
  z-index: 1;
}

.tooltip[x-placement^="top"] {
  margin-bottom: 5px;
}

.tooltip[x-placement^="top"] .tooltip-arrow {
  border-width: 5px 5px 0 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  bottom: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="bottom"] {
  margin-top: 5px;
}

.tooltip[x-placement^="bottom"] .tooltip-arrow {
  border-width: 0 5px 5px 5px;
  border-left-color: transparent !important;
  border-right-color: transparent !important;
  border-top-color: transparent !important;
  top: -5px;
  left: calc(50% - 5px);
  margin-top: 0;
  margin-bottom: 0;
}

.tooltip[x-placement^="right"] {
  margin-left: 5px;
}

.tooltip[x-placement^="right"] .tooltip-arrow {
  border-width: 5px 5px 5px 0;
  border-left-color: transparent !important;
  border-top-color: transparent !important;
  border-bottom-color: transparent !important;
  left: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip[x-placement^="left"] {
  margin-right: 5px;
}

.tooltip[x-placement^="left"] .tooltip-arrow {
  border-width: 5px 0 5px 5px;
  border-top-color: transparent !important;
  border-right-color: transparent !important;
  border-bottom-color: transparent !important;
  right: -5px;
  top: calc(50% - 5px);
  margin-left: 0;
  margin-right: 0;
}

.tooltip.popover .popover-inner {
  background: #f9f9f9;
  color: black;
  padding: 24px;
  border-radius: 5px;
  box-shadow: 0 5px 30px rgba(black, .1);
}

.tooltip.popover .popover-arrow {
  border-color: #f9f9f9;
}

.tooltip[aria-hidden='true'] {
  visibility: hidden;
  opacity: 0;
  transition: opacity .15s, visibility .15s;
}

.tooltip[aria-hidden='false'] {
  visibility: visible;
  opacity: 1;
  transition: opacity .15s;
}

.force-graph-container .graph-tooltip {
    position: absolute;
    top: 0;
    transform: translate(-50%, -75px);
    font-size: 14px !important;
    padding: 4px;
    border-radius: 3px;
    color: none !important;
    background: none !important;
    visibility: hidden;
}

.label {
  display: flex;
  align-content: center;
  align-items: center;
  border-top: 1px solid #5a5a5a;
}

.labelTwo {
  display: flex;
  align-content: center;
  align-items: center;
  border-radius: 4px;
}

.add-button {
  border-top: 1px solid #5a5a5a;
  border-radius: 100%
}

.labeltext {
  font-family: "IBM Plex Mono";
  background: black;
  font-size: .75rem;
  white-space: pre;
  padding: 3px 6px;
}
.labelsubs {
  font-family: "IBM Plex Mono";
  background: rgb(57, 57, 57);
  font-size: 10px;
  white-space: pre;
  padding: 2px 6px;
}
</style>